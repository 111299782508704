/* colors */

/* layout widhts */

/* others */
/*
 * '<=' and '>' are actually allowed in scss files
 * due to MiniCssExtractPlugin
 * though linter does not know that
 */

/* shared styles */
.cm_flex {
  display: flex;
}
.cm_flex-grow {
    flex-grow: 1;
  }
.cm_flex-center {
    align-items: center;
    display: flex;
  }
@media only screen and (max-width: 1024px) {
.cm_flex-desktop {
      display: initial
  }
  }
@media only screen and (max-width: 1024px) {
.cm_FacetPanel:not(.dialog-body) {
    display: none
}
  }
.cm_FacetPanel:not(.cm_facet-panel__full-width),
.cm_facet-panel__narrow {
  width: 270px;
  margin-right: 25px;
  float: left;
}
.cm_main-content {
  width: calc(100% - 270px - 25px);
  float: left;
}
@media only screen and (max-width: 1024px) {
.cm_main-content {
    width: 100%
}
  }
.cm_SearchResult .cm_loading {
  height: 100vh;
  width: 100%;
}
@media only screen and (min-width: 1024.02px) {
.cm_desktop-hide {
    display: none !important
}
  }
@media only screen and (max-width: 1024px) {
.cm_mobile-hide {
    display: none !important
}
  }
.cm_hide {
  display: none !important;
}
.cm_space {
  padding: 8px;
}
.cm_vertical-space {
  padding-top: 8px;
  padding-bottom: 8px;
}
.cm_h2 {
  font-size: 20px;
  text-transform: uppercase;
}

/* components */
.cm_dialog {
  /* common */
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0009;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cm_dialog:empty,
  .cm_dialog.cm_hide {
    display: none;
  }
.cm_dialog .dialog-container {
    position: relative;
    margin: 40px;
    max-height: 100%;
  }
.cm_dialog .close {
    position: absolute;
    right: -35px;
    top: -35px;
    padding: 5px;
    cursor: pointer;
    line-height: 0;
    z-index: 2;
  }
.cm_dialog .close:hover {
      background-color: #0006;
    }
.cm_dialog .close .cm_icon {
      stroke: rgb(171, 171, 171);
      stroke-linecap: round;
      stroke-width: 2;
      height: 20px;
      padding: 0;
    }
.cm_dialog .dialog-content {
    background-color: rgb(255, 255, 255);
    overflow: hidden auto;
    height: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }
.cm_dialog .dialog-footer {
    position: sticky;
    bottom: 0;
    margin: 0 -10px;
    background-color: inherit;
    border-top: 1px solid rgb(187, 187, 187);
    text-align: right;
  }
.cm_dialog .dialog-footer-content {
    padding: 10px;
  }
.cm_dialog .dialog-title {
    flex: none;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 700;
    margin: 10px 0;
    padding-right: 30px;
  }
.cm_dialog .dialog-body {
    position: relative;
    flex: 1;
  }
@media only screen and (max-width: 1024px) {
.cm_dialog {
    align-items: stretch;
    justify-content: flex-end
}
    .cm_dialog .dialog-container {
      margin: 0;
    }
    .cm_dialog .close {
      right: 8px;
      top: 8px;
    }
  }
/* search box dialog */
.cm_dialog.SearchBoxDialog div[data-focus-lock-disabled] {
      background: rgb(255, 255, 255);
      height: 100%;
      width: 100%;
    }
.cm_dialog.SearchBoxDialog div[data-autofocus-inside] {
      display: flex;
      width: 100%;
    }
.cm_dialog.SearchBoxDialog .dialog-container {
      margin: 0;
    }
.cm_dialog.SearchBoxDialog .dialog-header {
      position: relative;
    }
.cm_dialog.SearchBoxDialog .dialog-content {
      padding: 0 10px;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      box-sizing: border-box;
      flex-wrap: nowrap;
    }
.cm_dialog.SearchBoxDialog .close {
      opacity: 1;
      right: 0;
      top: 10px;
    }
/* facet dialog */
.cm_dialog.facet-dialog .dialog-container {
      flex-basis: calc(270px + 10px * 2);
    }
.cm_dialog.facet-dialog .cm_showResultsButton {
      width: 100%;
    }
.cm_dialog.FacetDialog .dialog-content, .cm_dialog.SimpleColorPaletteDialog .dialog-content, .cm_dialog.ColorExtractorDialog .dialog-content {
      padding: 0;
      overflow: hidden;
    }
.cm_dialog.FacetDialog .dialog-header, .cm_dialog.SimpleColorPaletteDialog .dialog-header, .cm_dialog.ColorExtractorDialog .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2147483647;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
    }
.cm_dialog.FacetDialog .dialog-title, .cm_dialog.SimpleColorPaletteDialog .dialog-title, .cm_dialog.ColorExtractorDialog .dialog-title {
      margin: 0;
    }
.cm_dialog.FacetDialog .buttons-container, .cm_dialog.SimpleColorPaletteDialog .buttons-container, .cm_dialog.ColorExtractorDialog .buttons-container {
      display: flex;
    }
.cm_dialog.FacetDialog .buttons-container .cm_button, .cm_dialog.SimpleColorPaletteDialog .buttons-container .cm_button, .cm_dialog.ColorExtractorDialog .buttons-container .cm_button {
        margin: 0;
        margin-left: 10px;
        padding: 0.2rem 0.5rem;
      }
.cm_dialog.FacetDialog .dialog-body, .cm_dialog.SimpleColorPaletteDialog .dialog-body, .cm_dialog.ColorExtractorDialog .dialog-body {
      overflow: hidden auto;
    }
/* new facet dialog */
.cm_dialog.FacetDialog .dialog-container {
      flex-basis: 320px;
    }
.cm_dialog.FacetDialog .dialog-header {
      padding: 5px 10px;
    }
.cm_dialog.FacetDialog .total-hits {
      font-size: 0.8rem;
      opacity: 0.7;
    }
.cm_dialog.FacetDialog .dialog-body {
      padding-top: 4px;
    }
.cm_dialog.FacetDialog .cm_filterChips {
      padding-top: 8px;
    }
.cm_dialog.FacetDialog .cm_filterChips .facettitle,
      .cm_dialog.FacetDialog .cm_filterChips .cm_btn,
      .cm_dialog.FacetDialog .cm_filterChips .cm_filter-chips_button {
        display: none;
      }
.cm_dialog.FacetDialog .cm_filterChips .facetbody {
        margin: 0;
      }
.cm_dialog.FacetDialog .facetholder {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
.cm_dialog.FacetDialog .facettitle {
      margin-bottom: 0;
      padding: 10px 28px 10px 10px;
    }
.cm_dialog.FacetDialog .facettitle .cm_icon {
        top: 10px;
      }
.cm_dialog.FacetDialog .facetbody {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0 10px;
    }
.cm_dialog.FacetDialog .facetbody.collapsed {
        margin: 0;
      }
.cm_dialog.FacetDialog .cm_vehicleSpecs .facettitle {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
      }
.cm_dialog.FacetDialog .cm_vehicleSpecs .facettitle .cm_button {
          margin: 0 !important;
        }
.cm_dialog.FacetDialog .cm_vehicleSpecs .cm_select:last-of-type {
        margin-bottom: 0;
      }
@media only screen and (min-width: 1024.02px) {
.cm_dialog.SimpleColorPaletteDialog .dialog-content, .cm_dialog.ColorExtractorDialog .dialog-content {
        padding: 0 10px
    }
  }
.cm_dialog.SimpleColorPaletteDialog .dialog-header, .cm_dialog.ColorExtractorDialog .dialog-header {
      padding: 13px 10px;
    }
@media only screen and (min-width: 1024.02px) {
.cm_dialog.SimpleColorPaletteDialog .dialog-header, .cm_dialog.ColorExtractorDialog .dialog-header {
        box-shadow: none;
        padding-left: 0
    }
  }
.cm_dialog.SimpleColorPaletteDialog .dialog-title, .cm_dialog.ColorExtractorDialog .dialog-title {
      padding-right: 0;
    }
@media only screen and (min-width: 1024.02px) {
.cm_dialog.SimpleColorPaletteDialog .buttons-container, .cm_dialog.ColorExtractorDialog .buttons-container {
        display: none
    }
  }
@media only screen and (max-width: 1024px) {
.cm_dialog.SimpleColorPaletteDialog .dialog-body, .cm_dialog.ColorExtractorDialog .dialog-body {
        padding: 20px 20px 0
    }
  }
.cm_dialog.SimpleColorPaletteDialog .dialog-footer, .cm_dialog.ColorExtractorDialog .dialog-footer {
      margin: 0 -10px;
    }
.cm_dialog.SimpleColorPaletteDialog .dialog-footer .cm_button, .cm_dialog.ColorExtractorDialog .dialog-footer .cm_button {
        margin-left: 5px;
      }
@media only screen and (max-width: 1024px) {
.cm_dialog.SimpleColorPaletteDialog .dialog-footer, .cm_dialog.ColorExtractorDialog .dialog-footer {
        display: none
    }
  }
.cm_search-box-root {
  outline: none;
  position: relative;
}
.cm_search-box-root .cm_search-box_clear {
      font-size: 12px;
      cursor: pointer;
      font-family: sans-serif;
      background-color: transparent;
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
    }
.cm_search-box-root .cm_search-box_clear-container {
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 2;
      }
.cm_search-box-root .cm_search-box_form-container {
      display: flex;
      position: relative;
      width: 100%;
    }
.cm_search-box-root .cm_search-box_submit {
      cursor: pointer;
      transition-duration: 0s;
    }
.cm_search-box-root .cm_dropdown {
    width: 100%;
    min-width: 300px;
    cursor: default;
    border: 1px solid rgb(204, 204, 204);
    outline: none;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }
@media only screen and (max-width: 1024px) {
.cm_search-box-root .cm_dropdown {
      max-height: 600px;
      overflow-y: auto
  }
  }
@media only screen and (max-height: 750px) {
.cm_search-box-root .cm_dropdown {
      max-height: 450px
  }
    }
@media only screen and (max-height: 620px) {
.cm_search-box-root .cm_dropdown {
      max-height: 320px
  }
    }
@media only screen and (max-width: 375px) {
.cm_search-box-root .cm_dropdown {
      min-width: unset
  }
    }
.cm_search-box-root .cm_dropdown.empty {
      border: none;
    }
.cm_search-box-root .cm_dropdown .item {
      text-align: left;
      padding: 3px 6px;
      cursor: pointer;
      min-height: 0;
    }
.cm_search-box-root .cm_dropdown .item:hover,
      .cm_search-box-root .cm_dropdown .item:focus {
        background-color: rgb(204, 204, 204);
        color: rgb(0, 0, 0);
        outline: none;
      }
.cm_search-box-root .cm_dropdown .item.section-title {
        text-transform: uppercase;
        cursor: default;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
        color: rgb(0, 0, 0);
      }
.cm_search-box-root .cm_dropdown .item.text-item b {
        font-weight: 700;
        color: inherit;
      }
.cm_search-box-root .cm_dropdown .item.product-item,
      .cm_search-box-root .cm_dropdown .item.facet-value-item {
        display: flex;
        align-items: center;
      }
.cm_search-box-root .cm_dropdown .item.product-item .image-block, .cm_search-box-root .cm_dropdown .item.facet-value-item .image-block {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          background-color: rgb(255, 255, 255);
          margin-right: 0.4em;
          text-align: center;
          flex: none;
        }
.cm_search-box-root .cm_dropdown .item.product-item .image-block img, .cm_search-box-root .cm_dropdown .item.facet-value-item .image-block img {
            max-width: 100%;
            max-height: 100%;
          }
.cm_search-box-root .cm_dropdown .item.product-item .description-block {
          flex: 1;
          flex-direction: column;
          overflow-wrap: anywhere;
        }
.cm_search-box-root .cm_dropdown .item.button-item {
        font-weight: 600;
        padding-top: 5px;
        padding-bottom: 5px;
      }
.cm_search-box-root .cm_dropdown .item.facet-value-item .hit-count {
          margin-left: 3px;
        }
.cm_search-box-root .cm_dropdown .item.facet-value-item .description-block {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
.cm_search-box-root .cm_dropdown .item .hit-count {
        font-size: 14px;
      }
.cm_search-box-root .cm_dropdown .section-title-div {
      background-color: white;
    }
/* Shift4Shop styles */
.cm_search-box-root .cm_dropdown .price .sale-price::before {
      content: ' ';
    }
@media only screen and (max-width: 1024px) {
.cm_search-box-root .cm_searchInput {
      font-size: 16px !important
  }
  }
.cm_search-box-root:not(.cm_search-box-root__dialog) .cm_search-box_clear {
      opacity: 0.5;
    }
.cm_search-box-root:not(.cm_search-box-root__dialog) .cm_search-box_clear-container {
        right: 65px;
      }
.cm_search-box-root:not(.cm_search-box-root__dialog) .cm_search-box_clear-container:hover .cm_search-box_clear {
          opacity: 1;
        }
.cm_search-box-root:not(.cm_search-box-root__dialog) .cm_browseAllButton {
      font-size: 0.9em !important;
      line-height: initial;
    }
.cm_search-box-root__dialog {
    box-shadow: none;
    outline: none;
    margin: 0;
    padding: 0;
  }
.cm_search-box-root__dialog form {
      margin-bottom: 0;
    }
.cm_search-box-root__dialog .form-field {
      margin: 0;
    }
.cm_search-box-root__dialog .cm_search-box_container {
        font-size: 14px;
        line-height: 1.5;
        width: 100%;
      }
.cm_search-box-root__dialog .cm_search-box_clear {
        opacity: 1;
      }
.cm_search-box-root__dialog .cm_search-box_clear-container {
          right: 65px;
        }
.cm_search-box-root__dialog .cm_search-box_submit {
        background-color: rgb(69, 99, 179);
        border: 1px solid rgb(69, 99, 179);
        border-radius: 0 3px 3px 0;
        height: 100%;
        width: 4em;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: initial;
        z-index: 2;
        color: rgb(255, 255, 255);
      }
.cm_search-box-root__dialog .cm_search-box_submit svg {
          width: 20px;
          height: 20px;
          fill: rgb(255, 255, 255);
        }
.cm_search-box-root__dialog .cm_searchInput {
      border-radius: 3px 0 0 3px;
      border-right: none !important;
      padding-left: 5px;
      padding-right: 5rem;
      height: 100%;
      width: 100%;
    }
.cm_search-box-root__dialog .cm_dropdown {
      background: transparent;
      flex: none;
      max-height: 100%;
    }
.cm_search-box-root__dialog .cm_dropdown .section {
        border-left: none;
        border-right: none;
      }
.cm_search-box-root__dialog .cm_dropdown .item.button-item.cm_browseAllButton {
        border-left: none;
        border-right: none;
        border-bottom: none;

        text-transform: uppercase;
        background-color: white;
        color: rgb(0, 0, 0);
      }
.cm_search-box-root__dialog_open-button {
      position: relative;
    }
.cm_search-box-root__dialog_open-button .cm_search-box_clear-container .form-field {
          margin: 0;
        }
@media only screen and (max-width: 1024px) {
.cm_search-box-root__dialog_open-button .cm_search-box_clear-container {
          display: none
      }
  }
.cm_browseAllButton {
  color: rgb(0, 0, 0);
  background-color: white;
  display: flex;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.cm_browseAllButton:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
body {
  overflow-anchor: none; /* https://github.com/utatti/perfect-scrollbar/issues/612 */
}
.cm_loading {
  cursor: progress;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 4;
  text-align: center;
}
.cm_loading .loader-wrapper {
    display: inline-block;
    margin-top: 50px;
  }
.cm_loading .loader-wrapper .dots {
      margin: 24px 0;
      list-style: none;
    }
.cm_loading .loader-wrapper .dots .dot {
        top: 0;
        background-color: rgb(255, 255, 255);
        border-radius: 100%;
        width: 12px;
        height: 12px;
        position: relative;
        margin: 0 2px;
        display: inline-block;
        animation-name: cm_loading-float;
        animation-duration: 1.8s;
        animation-iteration-count: infinite;
        transition:
          top 0.7s linear,
          background-color 4s linear;
      }
.cm_loading .loader-wrapper .dots .dot:nth-child(2) {
          transform: translateY(3px);
          animation-delay: 0.2s;
        }
.cm_loading .loader-wrapper .dots .dot:nth-child(3) {
          transform: translateY(4px);
          animation-delay: 0.4s;
        }
@keyframes cm_loading-float {
  0%,
  70% {
    background-color: rgb(170, 170, 170);
    top: -4px;
  }
  70%,
  85% {
    background-color: rgb(238, 238, 238);
    top: 4px;
  }
  75%,
  100% {
    background-color: rgb(255, 255, 255);
    top: 0;
  }
}
.cm_select {
  position: relative;
  cursor: default;
}
.cm_select .cm_option_title {
    color: rgb(69, 99, 179);
    font-weight: 600;
  }
.cm_select .cm_option_title:empty {
      display: none;
    }
.cm_select__native,
  .cm_select_inner-select {
    /* If the select font-size is less than 16px, it triggers auto-zoom on iOS devices.
     * This leads to bad UX (i.e., a user needs to zoom out to see search results).
     */
    font-size: 16px !important;
  }
.cm_select_toggle * {
      pointer-events: none;
    }
.cm_select_toggle__native * {
      pointer-events: auto;
    }
.cm_select_inner-select {
    width: 100%;
    height: 100%;
  }
.cm_select_dropdown {
    max-height: 350px;
    overflow: auto;
  }
.cm_select_dropdown .option {
      line-height: 25px;
      padding-left: 5px;
    }
.cm_select__pretty {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1.5em 0 5px;
    margin: 0;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255) url('Stores/_default-store/images/select-bg.svg') no-repeat 100%
      48%;
    line-height: inherit;
  }
@font-face {
  font-family: 'ConvermaxStar';
  src: url('data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAScAA0AAAAABrAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAAEgAAAABoAAAAcbyQ+3kdERUYAAARgAAAAHgAAACAAMwAGT1MvMgAAAZgAAABGAAAAVi+vS9xjbWFwAAAB8AAAAEAAAAFKwBMjvmdhc3AAAARYAAAACAAAAAj//wADZ2x5ZgAAAkAAAAEJAAABdH33LXtoZWFkAAABMAAAAC0AAAA2BroQKWhoZWEAAAFgAAAAHAAAACQD5QHQaG10eAAAAeAAAAAPAAAAFAYAAABsb2NhAAACMAAAAA4AAAAOAO4AeG1heHAAAAF8AAAAHAAAACAASgAvbmFtZQAAA0wAAADeAAABkorWfVZwb3N0AAAELAAAACkAAABEp3ubLXgBY2BkYADhPPP4OfH8Nl8ZuJkYQODS2fRrCPr/aSYGxq1ALgcDWBoAO60LkwAAAHgBY2BkYGDc+v80gx4TAwgASaAICmABAFB+Arl4AWNgZGBgYGPQYWBiAAIwyQgWc2AAAwAHVQB6eAFjYGRiYJzAwMrAwejDmMbAwOAOpb8ySDK0MDAwMbByMsCBAAMCBKS5pjA4PGB4wMR44P8BBj3GrQymQGFGkBwAjtgK/gAAeAFjYoAAEA1jAwAAZAAHAHgB3crBCcAwDEPRZydkih567CDdf4ZskmLwFBV8xBfCaC4BXkOUmx4sU0h2ngNb9V0vQCxaRKIAevT7fGWuBrEAAAAAAAAAAAA0AHgAugAAeAF9z79Kw1AUx/FzTm7un6QmJtwmQ5Bg1abgEGr/BAqlU6Gju+Cgg1MkQ/sA7Vj7BOnmO/gUvo2Lo14NqIO6/IazfD8HEODtmQCfoANwNsyp2/GJt3WKQrd1NLiYYWx2PBqOsmJMEOznPOTzfSCrhAtbbLdmeFLJV9eKd63WLrZcIcuaEVdssWCKM6pLCfTVOYbz/0pNSMSZKLIZpvh78sAUH6PlMrreTCabP9r+Z/puPZ2ur/RqpQHgh+MIegCnXeM4MRAPjYN//5tj4ZtTjkFqEdmeMShlEJ7tVAly2TAkx6R68Fl4E/aVvn8JqHFQ4JS1434gXKcuL31dDhzs3YbsEOAd/IU88gAAAHgBfY4xTgMxEEVfkk0AgRCioKFxQYd2ZRtpixxgRU2RfhU5q5VWseQ4JdfgAJyBlmNwAM7ABRhZQ0ORwp7nr+eZAa54YwYg9zm3ynPOeFRe8MCrciXOh/KSS76UV5L/iDmrLiS5AeU519wrL3jmSbkS5115yR2fyivJv9kx0ZMZ2RLZw27q87iNQi8EBo5FSPIMw3HqBboi5lKTGAGDp8FKXWP+t9TU01Lj5His1Ba6uM9dTEMwvrFmbf5GC/q2drW3ruXUhhsCiQOjznFlCzYhHUZp4xp76vsvQh89CQAAeAFjYGJABowM6IANLMrEyMTIzMjCXpyRWJBqZshWXJJYBKOMAFHFBucAAAAAAAAB//8AAngBY2BkYGDgA2IJBhBgAvKZGViBJAuYxwAABJsAOgAAeAFjYGBgZACCk535hiD60tn0azAaAEqpB6wAAA==')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
.cm_star {
  font-family: 'ConvermaxStar' !important;
  font-weight: bold;
  color: rgb(253, 192, 40);
  display: inline;
}
.cm_star:not(:last-of-type) {
    padding-right: 4px;
  }
.cm_star__empty::before {
    content: '\e001';
  }
.cm_star__half::before {
    content: '\e002';
  }
.cm_star__full::before {
    content: '\e000';
  }
.cm_vehicle-categories {
  position: relative;
}
.cm_vehicle-categories:not(.cm_vehicle-categories__flat) .cm_vehicle-categories_category-wrapper-title {
    margin-top: 40px;
    margin-bottom: 15px;
  }
a.cm_vehicle-categories_link {
    -webkit-text-decoration: none;
    text-decoration: none;
    color: rgb(0, 0, 0);
  }
a.cm_vehicle-categories_category-wrapper-title-link:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }
.cm_vehicle-categories_category-wrapper {
    padding: 20px 0;
  }
.cm_vehicle-categories_category-wrapper:empty {
      display: none;
    }
.cm_vehicle-categories_category-wrapper-title {
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
  }
.cm_vehicle-categories_category-wrapper-title-link {
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
.cm_vehicle-categories_category-repeater {
    margin: 0 -5px;
  }
@media only screen and (max-width: 1024px) {
.cm_vehicle-categories_category-repeater {
      margin: 0 5px
  }
  }
.cm_vehicle-categories_category-repeater {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
}
@media only screen and (max-width: 1024px) {
.cm_vehicle-categories_category-repeater {
    grid-template-columns: repeat(4, 1fr)
  }
  }
@media only screen and (max-width: 768px) {
.cm_vehicle-categories_category-repeater {
    grid-template-columns: repeat(3, 1fr)
  }
  }
@media only screen and (max-width: 425px) {
.cm_vehicle-categories_category-repeater {
    grid-template-columns: repeat(2, 1fr)
  }
  }
.cm_vehicle-categories_category-container {
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 10px;
  }
.cm_vehicle-categories_category-container:hover .cm_vehicle-categories_category-title-container {
        -webkit-text-decoration: underline;
        text-decoration: underline;
      }
.cm_vehicle-categories_category-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    width: 100%;
  }
@media (max-width: 425px) {
.cm_vehicle-categories_category-image-container {
      height: 130px
  }
    }
.cm_vehicle-categories_category-image {
    background-color: rgb(255, 255, 255);
    display: block;
    -o-object-fit: contain;
       object-fit: contain;
    max-height: 230px;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
.cm_vehicle-categories_category-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;
    height: 2.2em;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0 10px;
    text-align: center;
    word-break: break-word;
  }
.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper-repeater {
        margin: 0 -5px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
      }
@media only screen and (max-width: 1024px) {
.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper-repeater {
    grid-template-columns: repeat(4, 1fr)
      }
  }
@media only screen and (max-width: 768px) {
.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper-repeater {
    grid-template-columns: repeat(3, 1fr)
      }
  }
@media only screen and (max-width: 425px) {
.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper-repeater {
    grid-template-columns: repeat(2, 1fr)
      }
  }
.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper {
        padding: 0;
      }
.cm_vehicle-categories__topline {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
  }
.cm_vehicle-categories__topline-wrapper {
    padding: 5px;
    width: 10%;
  }
@media only screen and (max-width: 768px) {
.cm_vehicle-categories__topline-wrapper {
      width: 16.5%
  }
    }
@media only screen and (max-width: 425px) {
.cm_vehicle-categories__topline-wrapper {
      width: 25%
  }
    }
.cm_vehicle-categories__topline-link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
.cm_vehicle-categories__topline-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
    height: 2.9em;
    color: rgb(0, 0, 0);
    font-size: 0.9em;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
    word-break: break-word;
  }
.cm_vehicle-categories__tabs {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    max-width: none;
    padding: 0;
  }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-titles {
        border-bottom: 1px solid rgb(238, 238, 238);
        margin-bottom: 25px;
        background-color: rgb(240, 240, 240);
        display: grid;
        grid-gap: 0;
        grid-template-rows: 1fr;
        grid-column: 1;
        justify-items: center;
        padding: 0 15px;
      }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-title {
        display: block;
        padding: 10px 0;
      }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-title.is-active span {
          border-bottom: 2px solid rgb(50, 50, 50);
        }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-title-image {
          width: 100%;
        }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-title-value {
          font-weight: 700;
          margin: 0 auto;
          text-align: center;
        }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tabs-repeater {
        padding: 0 10px 27px;
      }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-container {
        display: grid;
        grid-gap: 10px;
        grid-auto-rows: max-content;
        grid-template-columns: 1fr 1fr;
        padding: 0;
        position: sticky;
        top: 0;
      }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-container:not(.is-active) {
          display: none;
        }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-container.is-active {
          position: sticky;
          top: 0;
        }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-body {
        background-color: rgb(255, 255, 255);
        box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 30%);
      }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-body-image {
          padding: 10px 25px 0;
          width: 100%;
        }
.cm_vehicle-categories__tabs .cm_vehicle-categories_tab-body-value {
          clear: left;
          color: rgb(50, 50, 50);
          font-size: 12px;
          font-weight: 400;
          float: left;
          line-height: 16px;
          margin: 5px auto;
          overflow-wrap: break-word;
          padding: 0 5px;
          text-align: center;
          width: 100%;
        }
@media (min-width: 1024px) {
.cm_vehicle-categories__tabs {
      grid-template-columns: 1fr
  }
        .cm_vehicle-categories__tabs .cm_vehicle-categories_tab-titles {
          grid-template-columns: repeat(5, 1fr);
          grid-template-rows: 1fr;
          grid-gap: 20px;
        }
        .cm_vehicle-categories__tabs .cm_vehicle-categories_tab-title-image {
          width: 175px;
        }
        .cm_vehicle-categories__tabs .cm_vehicle-categories_tab-title-value {
          font-size: 1.3em;
          margin: 10px auto;
        }

        .cm_vehicle-categories__tabs .cm_vehicle-categories_tab-container {
          grid-gap: 20px;
          grid-auto-columns: minmax(0, 15%);
          grid-template-rows: auto;
          grid-template-columns: unset;
          grid-auto-rows: unset;
          grid-auto-flow: column;
          justify-content: center;
          position: relative;
          top: inherit;
        }
        .cm_vehicle-categories__tabs .cm_vehicle-categories_tab-body-value {
          font-size: 16px;
          line-height: 20px;
          margin: 20px auto;
        }
    }
.cm_vehicle-categories__alphabetical-grid .cm_vehicle-categories_category-wrapper {
          border-bottom: 1px solid rgb(221, 221, 221);
        }
.cm_vehicle-categories__alphabetical-grid .cm_vehicle-categories_category-image-container {
          border: 1px solid rgb(221, 221, 221);
          border-radius: 5px;
          padding: 5px;
        }
.cm_vehicle-categories__alphabetical-grid .cm_vehicle-categories_category-image-container h3 {
            margin-bottom: 0;
            text-align: center;
          }
.cm_vehicle-categories__alphabetical-grid .cm_vehicle-categories_category-container:hover .cm_vehicle-categories_category-title {
            color: rgb(69, 99, 179);
          }
.cm_vehicle-categories__alphabetical-grid .cm_vehicle-categories_category-container:hover .cm_vehicle-categories_category-image-container {
            border-color: rgb(69, 99, 179);
          }
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper-repeater:not(.filtered) {
            -moz-column-count: 4;
                 column-count: 4;
          }
@media (max-width: 768px) {
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper-repeater:not(.filtered) {
              -moz-column-count: 3;
                   column-count: 3
          }
            }
@media (max-width: 425px) {
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper-repeater:not(.filtered) {
              -moz-column-count: 2;
                   column-count: 2
          }
            }
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper-repeater.filtered .cm_vehicle-categories_category-repeater {
            display: inline-grid;
            width: 100%;
          }
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper-repeater.filtered .cm_vehicle-categories_category-repeater-container {
              -moz-column-count: 1;
                   column-count: 1;
            }
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-repeater {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          margin: 0;
          grid-gap: 0;
        }
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper {
          page-break-inside: avoid;
          -moz-column-break-inside: avoid;
               break-inside: avoid;
        }
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper-title-link {
          margin-bottom: 10px;
          color: rgb(0, 0, 0);
          text-align: left;
        }
.cm_vehicle-categories__alphabetical-columns .cm_vehicle-categories_category-wrapper-title-link:hover {
            color: rgb(69, 99, 179);
          }
.cm_vehicle-categories__letters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  }
.cm_vehicle-categories__letters-letter {
      background: rgb(255, 255, 255);
      border: 1px solid rgb(230, 230, 230);
      color: rgb(0, 0, 0);
      font-size: 1.2em;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
    }
.cm_vehicle-categories__letters-letter:hover:not(.disabled),
      .cm_vehicle-categories__letters-letter.active {
        color: rgb(69, 99, 179);
      }
.cm_vehicle-categories__letters-letter.active {
        background-color: rgb(243, 243, 243);
        border-color: rgb(69, 99, 179);
      }
.cm_vehicle-categories__letters-letter:not(.disabled) {
        cursor: pointer;
      }
.cm_vehicle-categories__letters-letter.disabled {
        pointer-events: none;
        color: rgb(179, 179, 179);
      }
.cm_vehicle-categories__subcategories {
    max-width: 100vw;
  }
.cm_vehicle-categories__subcategories .cm_vehicle-categories_category-wrapper {
      scroll-snap-align: none;
      scroll-snap-stop: normal;
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
      -webkit-user-drag: none;
      width: 180px;
    }
@media only screen and (max-width: 1024px) {
.cm_vehicle-categories__subcategories .cm_vehicle-categories_category-wrapper {
        width: 150px
    }
  }
.cm_vehicle-categories__subcategories .cm_vehicle-categories_category-wrapper-repeater {
      margin: 0;
    }
.cm_vehicle-categories__subcategories .cm_vehicle-categories_category-wrapper-repeater:not(:empty) {
        margin: 20px 0;
      }
.cm_vehicle-categories__subcategories .cm_vehicle-categories_category-image-container {
      height: 110px;
    }
@media only screen and (max-width: 1024px) {
.cm_vehicle-categories__subcategories .cm_vehicle-categories_category-image-container {
        height: 90px
    }
  }
.cm_vehicle-categories__subcategories .cm_vehicle-categories_category-title {
      font-size: 1em;
    }
.cm_vehicle-categories__manufacturers .cm_vehicle-categories_category-title-container {
    display: none;
  }
.cm_vehicle-categories__manufacturer-models .cm_vehicle-categories_category-container {
    height: 100%;
  }
.cm_vehicle-categories__manufacturer-models .cm_vehicle-categories_category-title {
    height: unset;
    display: block;
  }
.cm_vehicle-widget__vehicle-category-wrapper {
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
}
.cm_vehicle-widget__vehicle-category-wrapper:empty {
    margin-bottom: 0;
  }

[data-cm-scroll-container] {
  grid-auto-flow: column;
  gap: 8px;
  overflow: auto hidden;
  padding-bottom: 10px;
  scroll-snap-type: inline mandatory;
}

[data-cm-scroll-container] * {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    -webkit-user-drag: none;
  }

[data-cm-scroll-container]::-webkit-scrollbar {
    height: 6px;
    width: 80%;
  }

[data-cm-scroll-container]::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: white;
    }

[data-cm-scroll-container]::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgb(69, 99, 179);
    }

[data-cm-scroll-left],
[data-cm-scroll-right] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  min-width: unset;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  transition: opacity 0.3s;
  border: none;
}

[data-cm-scroll-left]:not(:disabled), [data-cm-scroll-right]:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.7;
    cursor: pointer;
  }

[data-cm-scroll-left]:not(:disabled):hover, [data-cm-scroll-right]:not(:disabled):hover {
      opacity: 1;
    }

[data-cm-scroll-left]:disabled, [data-cm-scroll-right]:disabled {
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0.4;
    cursor: not-allowed;
  }

[data-cm-scroll-left]:before, [data-cm-scroll-right]:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-top: 6px solid rgb(255, 255, 255);
    border-right: 6px solid rgb(255, 255, 255);
    border-radius: 2px 0;
  }

[data-cm-scroll-left] {
  left: 10px;
}

[data-cm-scroll-left]:before {
    transform: rotate(-135deg);
    margin-right: -6px;
  }

[data-cm-scroll-right] {
  right: 10px;
}

[data-cm-scroll-right]:before {
    transform: rotate(45deg);
    margin-left: -6px;
  }

.cm_FacetPanel .input {
    display: flex;
    align-items: stretch;
    margin: 8px 0;
  }

.cm_FacetPanel .input input {
      flex: auto;
      /* Somehow without this inputs gets much wider than they need to fit the container.
       * `flex-basis: 0` doesn't fix the problem
       */
      width: 0;
    }

.cm_FacetPanel .input .separator {
      flex: none;
      align-self: center;
      margin: 0 5px;
    }

.cm_FacetPanel .input button {
      flex: none;
      width: unset;
      padding: 0 10px;
      margin: 0 0 0 5px;
    }

.cm_FacetPanel .input .cm_input-error {
      background-color: #fcc3;
      border-color: rgb(255, 204, 204);
    }

.cm_FacetPanel .input input[type='number'] {
      -moz-appearance: textfield;
    }

.cm_FacetPanel .input input[type='number']::-webkit-inner-spin-button,
        .cm_FacetPanel .input input[type='number']::-webkit-outer-spin-button,
        .cm_FacetPanel .input input[type='number']:hover::-webkit-inner-spin-button,
        .cm_FacetPanel .input input[type='number']:hover::-webkit-outer-spin-button {
          -webkit-appearance: none;
          display: none;
          margin: 0;
        }

.cm_tooltip .tooltip-content {
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 1;

    pointer-events: none;
    opacity: 0;
    transition: opacity 400ms;

    border: 2px solid rgb(221, 221, 221);
    border-radius: 4px;
    padding: 8px;

    background-color: rgb(255, 255, 255);
    font-family: Verdana, Arial, sans-serif;
    font-size: 1.1em;
    white-space: pre-line;

    width: -moz-max-content;

    width: max-content;
    max-width: 600px;
  }

@media only screen and (max-width: 630px) {

.cm_tooltip .tooltip-content {
      max-width: 320px
  }
    }

@media only screen and (max-width: 350px) {

.cm_tooltip .tooltip-content {
      max-width: 280px
  }
    }

.cm_tooltip.touched .tooltip-content {
    opacity: 1;
  }

@media only screen and (min-width: 1024.02px) {
    .cm_tooltip:hover .tooltip-content {
      opacity: 1;
    }
  }

.cm_facetToggle {
  margin-right: 30px;
}

@media only screen and (max-width: 1024px) {

.cm_facetToggle {
    order: 10;
    width: 100%;
    margin-top: 20px
}
  }

.cm_facet-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm_facet-toggle_switch {
  position: relative;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 24px;
  margin: 0;
}

.cm_facet-toggle_input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cm_facet-toggle_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(204, 204, 204);
  transition: 0.4s;
  border-radius: 34px;
}

.cm_facet-toggle_slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

.cm_facet-toggle_input:checked + .cm_facet-toggle_slider {
  background-color: rgb(69, 99, 179);
}

.cm_facet-toggle_input:checked + .cm_facet-toggle_slider::before {
    transform: translateX(16px);
  }

body.cm_hide-facet-toggle .cm_facetToggle,
  body.cm_hide-facet-toggle .cm_universal-badge {
    display: none;
  }

.cm_FacetPanel .facettitle {
  font-weight: 500;
  padding: 8px 28px 8px 8px;
  margin-bottom: 20px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  background-color: white;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
}

.cm_FacetPanel .facettitle .cm_icon {
    position: absolute;
    top: 8px;
    right: 8px;
    box-sizing: border-box;
    height: 20px;
    padding: 4px;
    fill: rgb(0, 0, 0);
  }

.cm_FacetPanel .facetbody {
  text-align: left;
  margin-bottom: 20px;
}

.cm_FacetPanel .facetbody .cmRepeater_Values.collapsed {
    display: none;
  }

@media only screen and (min-width: 1024.02px) {

.cm_FacetPanel .facetbody .facetvalues.overflowed {
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 355px;
      padding-right: 10px
  }
      .cm_FacetPanel .facetbody .facetvalues.overflowed::-webkit-scrollbar {
        width: 6px;
        height: 8px;
      }
      .cm_FacetPanel .facetbody .facetvalues.overflowed::-webkit-scrollbar-thumb {
        background: rgb(0, 0, 0);
        border-radius: 5px;
      }
      .cm_FacetPanel .facetbody .facetvalues.overflowed::-webkit-scrollbar-track {
        background: rgb(243, 243, 243);
      }
  }

.cm_FacetPanel .facetdiv {
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5em;
  align-items: baseline;
}

.cm_FacetPanel .facetdiv input[type='checkbox'] {
    flex: 0 0 auto;
  }

.cm_FacetPanel .facetdiv input[type='checkbox'].custom {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
    }

.cm_FacetPanel .facetdiv a {
    text-decoration-line: none;
    color: rgb(0, 0, 0);
  }

.cm_FacetPanel .facetdiv:hover {
    color: rgb(69, 99, 179);
  }

.cm_FacetPanel .facetdiv:hover a {
      color: rgb(69, 99, 179);
    }

.cm_FacetPanel .facetdiv:empty {
    min-height: unset;
  }

.cm_FacetPanel .facetentrykey {
  flex: 1 1 auto;
}

.cm_FacetPanel .facetentryval {
  flex: 0 0 auto;
  margin-left: 0.5em;
  text-align: right;
  font-weight: 500;
}

.cm_FacetPanel .cm_facet_checkbox {
  position: relative;
  margin: 0 5px 0 0;
  bottom: -1px;
}

.cm_FacetPanel .filter-input {
  position: relative;
}

.cm_FacetPanel .filter-input .facetdiv {
    cursor: default;
  }

.cm_FacetPanel .filter-input .facetentrykey {
    margin-left: 12px;
  }

.cm_FacetPanel .filter-input .input {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 5px;
  }

.cm_FacetPanel .filter-input_clear {
    margin-right: 15px;
    font-size: 14px;
  }

.cm_FacetPanel .filter-input_clear-container {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
  }

.cm_FacetPanel {
  position: relative;
}

/* for loading */

.cm_FacetPanel .cm_empty.cm_Facets {
      width: 100%;
      height: 100vh;
    }

.cm_FacetPanel .cm_empty.cm_FilterChips {
      display: none;
    }

.cm_FacetPanel.cm_empty {
    width: 100%;
    margin-right: 25px;
    float: left;
  }

.cm_FacetPanel.cm_empty:not(:empty) {
      height: 100vh;
    }

.cm_FacetPanel.cm_empty .cm_loading {
      width: 100%;
      height: 100%;
    }

.cm_FacetPanel.cm_empty:not(.cm_facet-panel__full-width) {
      width: 270px;
    }

.cm_FacetPanel.cm_empty + .cm_FacetPanel.cm_empty {
      display: none;
    }

/* inputs' and chips' button */

.cm_FacetPanel .cm_btn {
    background: rgb(255, 255, 255);
    border: 1px solid;
    color: rgb(0, 0, 0);
    text-transform: none;
    font-size: 13px;
    line-height: 2.2;
    font-weight: 700;
    padding: 0 15px;
    margin: 5px auto 0 auto;
    display: block;
    width: -moz-max-content;
    width: max-content;
    cursor: pointer;
  }

.cm_FacetPanel .cm_btn:hover {
      color: rgb(0, 0, 0);
      background-color: white;
    }

/* others */

.cm_FacetPanel .cm_filterChips .facetdiv:hover .cm_icon {
      stroke: rgb(69, 99, 179);
    }

.cm_FacetPanel .cm_filterChips .facettitle {
      cursor: auto;
    }

.cm_FacetPanel .cm_filterChips .facetentrykey {
      width: 73%;
    }

.cm_FacetPanel .cm_filterChips .facetentrykey::first-letter {
        text-transform: capitalize;
      }

.cm_FacetPanel .cm_filterChips .facetentryval .cm_icon {
        stroke: rgb(0, 0, 0);
        stroke-linecap: round;
        stroke-width: 3px;
        height: 0.7em;
      }

.cm_FacetPanel .cm_filterChips .cm_chip-name {
      font-weight: 600;
    }

.cm_FacetPanel .cm_filterChips .color-tile {
      display: inline-block;
      vertical-align: text-bottom;
      width: 16px;
      height: 16px;
    }

.cm_FacetPanel .cm_filterChips .color-tile.light {
        border: solid 1px rgb(221, 221, 221);
      }

.cm_FacetPanel .cm_show-all-container {
    justify-content: center;
  }

.cm_FacetPanel .cm_show-all-container:empty {
      display: none;
    }

.cm_FacetPanel .cm_show-all {
    display: block;
    margin-top: 10px;
  }

.cm_padding-1 {
    padding-left: 18px;
  }

.cm_padding-2 {
    padding-left: calc(18px * 2);
  }

.cm_padding-3 {
    padding-left: calc(18px * 3);
  }

.cm_padding-4 {
    padding-left: calc(18px * 4);
  }

/* icons */
.cm_icon-angle.left {
    transform: matrix(0, 1, -1, 0, 0, 0);
  }
.cm_icon-angle.up {
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
.cm_icon-angle.right {
    transform: matrix(0, 1, 1, 0, 0, 0);
  }

.cm_icon-arrow.up {
    transform: matrix(0, 1, 1, 0, 0, 0);
  }

.cm_icon-arrow.right {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

.cm_icon-arrow.down {
    transform: matrix(0, -1, 1, 0, 0, 0);
  }

.cm_icon_garage__background {
  background-image: url('Stores/_default-store/images/garage.svg');
  background-repeat: no-repeat;
  background-position-x: center;
}

/* global styles */
.clear {
  clear: both;
}
.clear-self::after {
  content: '';
  display: block;
  clear: both;
}

.cm_SearchResult,
.cm_Facets {
  position: relative;
}
/* shift4shop */
.cm_SearchResult .product-item .price .sale-price::before, .cm_search-dropdown .product-item .price .sale-price::before {
        content: ' ';
      }

/* message */
.cm_message:empty {
    display: none;
  }
.cm_message-link {
    color: rgb(69, 99, 179);
  }
.cm_message-header {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 0.83em;
  }
.cm_message > div {
    border-left: 5px solid rgb(69, 99, 179);
    background-color: rgb(243, 243, 243);
    padding: 1em;
    margin-bottom: 15px;
  }
.cm_message > div .query {
      font-style: italic;
      font-weight: 700;
      color: inherit;
    }
.cm_message > div a {
      cursor: pointer;
    }
.cm_message > div span {
      font-weight: bold;
    }

.cm_customMessage:empty {
  display: none;
}

/* searchHeader */
.cm_SearchHeader {
  display: flex;
}
@media only screen and (max-width: 1024px) {
.cm_SearchHeader {
    flex-direction: row-reverse
}
  }

.cm_facetDialogButton {
  flex: none;
}

@media only screen and (min-width: 1024.02px) {

.cm_facetDialogButton {
    display: none
}
  }

#cm_mfilter-btn-container .cm_facetDialogButton {
    text-align: center;
    padding-bottom: 15px;
  }

.cm_total-hits {
  flex: 1;
  padding: 10px 0;
}

@media only screen and (max-width: 1024px) {

.cm_total-hits {
    display: none
}
  }

.cm_sort {
  display: flex;
  align-items: center;
}

.cm_sort .sort-label {
    flex: none;
  }

@media only screen and (max-width: 1024px) {

.cm_sort .sort-label {
      display: none
  }
  }

.cm_sort select {
    height: 40px;
    width: auto;
  }

@media only screen and (max-width: 1024px) {

.cm_sort {
    flex: 1
}
  }

/* mainContent */
.cm_main-content .cm_SearchBox {
    width: 500px;
    margin-bottom: 15px;
    position: relative;
  }
@media only screen and (max-width: 1024px) {
.cm_main-content .cm_SearchBox {
      width: 100%
  }
  }
.cm_main-content .titles {
    margin: 0;
    display: inline-block;
  }
.cm_main-content .paging {
    margin: 10px;
    padding-left: 22px;
    width: 100%;
  }
.cm_main-content .cm_SearchResult {
    margin-top: 30px;
    min-height: 100vh;
  }

.cm_button {
  cursor: pointer;
}

.cm_button-refresh {
    background-image: url('Stores/_default-store/images/refresh.svg');
    filter: invert(99%) sepia(0%) saturate(3504%) hue-rotate(201deg) brightness(115%) contrast(101%);
    width: 16px;
    height: 16px;
  }

.cm_no-scroll {
  overflow: hidden;
  position: relative;
  height: auto;
}

.cm_filter-search-box {
  position: relative;
}

.cm_filter-search-box__clear {
    font-size: 12px;
    cursor: pointer;
    font-family: sans-serif;
    background-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    opacity: 0.5;
  }

.cm_filter-search-box__clear-container {
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 2;
      right: 45px;
    }

.cm_filter-search-box__clear-container:hover .cm_filter-search-box__clear {
        opacity: 1;
      }
.cm_FacetBarWheelsTires .cm_vehicle-widget_select-container {
    width: 100%;
  }
.cm_FacetBarWheelsTires .cmTemplate_selectFacet {
    flex: 1;
  }
.cm_FacetBarWheelsTires .cm_select .cm_dropdown_hitCount {
    display: none;
  }
.cm_FacetBarWheelsTires .cm_facet_select,
  .cm_FacetBarWheelsTires .cm_select_toggle {
    line-height: inherit;
  }
@media (min-width: 426px) {
    .cm_FacetBarWheelsTires .cmTemplate_selectFacet {
      margin-right: 10px;
    }
  }
@media (max-width: 425px) {
      .cm_FacetBarWheelsTires .cm_vehicle-widget_body,
      .cm_FacetBarWheelsTires .cm_vehicle-widget_select-container {
        flex-direction: column;
      }
      .cm_FacetBarWheelsTires .cm_vehicle-widget_button_clear {
        width: 100%;
      }
    .cm_FacetBarWheelsTires .cmTemplate_selectFacet {
      margin-bottom: 10px;
    }
  }
.cm_FacetPanel .cm_FacetBarWheelsTires .cm_vehicle-widget_body,
      .cm_FacetPanel .cm_FacetBarWheelsTires .cm_vehicle-widget_select-container {
        flex-direction: column;
      }
.cm_FacetPanel .cm_FacetBarWheelsTires .cm_vehicle-widget_button__clear {
        width: 100%;
      }
.cm_FacetPanel .cm_FacetBarWheelsTires .cm_vehicle-widget_select-container {
        padding-top: 0;
        padding-bottom: 0;
      }
.cm_FacetPanel .cm_FacetBarWheelsTires .cmTemplate_selectFacet {
      margin-bottom: 10px;
      margin-right: 0;
    }
.cm_FacetPanel .cm_field-FrontRear .facetentryval {
    display: none;
  }
.cm_fitment-table .fitment-table-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid rgb(238, 238, 238);
  }
.cm_fitment-table .fitment-row {
    display: flex;
    border-bottom: 1px solid rgb(238, 238, 238);
  }
.cm_fitment-table .fitment-row:first-child {
      font-weight: 700;
      border-width: 2px;
    }
.cm_fitment-table .fitment-row:last-child {
      border-width: 0;
    }
.cm_fitment-table .fitment-cell {
    display: block;
    flex: 1;
    border-right: 1px solid rgb(238, 238, 238);
    padding: 2px 4px;
    overflow-wrap: anywhere;
  }
.cm_fitment-table .fitment-cell__value + .fitment-cell__value {
      margin-top: 10px;
    }
.cm_fitment-table .fitment-cell:last-child {
      border-width: 0;
    }
.cm_fitment-table .cm_vehicleSelector {
    padding: 2px 0;
  }
.cm_fitment-table .cm_vehicleSelector button {
      width: 100%;
      text-wrap: nowrap;
    }
.cm_fitment-table .cm_vehicleSelector button,
    .cm_fitment-table .cm_vehicleSelector .cm_select_inner-select {
      height: 40px;
    }
.cm_fitment-table__horizontal .cm_vehicleSelector {
      display: flex;
    }
.cm_fitment-table__horizontal .cm_vehicleSelector button {
        width: 100%;
        text-wrap: nowrap;
        width: -moz-max-content;
        width: max-content;
      }
.cm_fitment-table__horizontal .cm_vehicleSelector .cm_select_inner-select {
        width: -moz-max-content;
        width: max-content;
        margin-right: 4px;
      }
.cm_fitment-table__vertical .fitment-row:first-child {
        font-weight: unset;
        border-bottom-width: 1px;
      }
.cm_fitment-table__vertical .fitment-table__inner-container {
      border-top: 1px solid;
      border-bottom: 1px solid;
    }
.cm_fitment-table__vertical .fitment-cell:first-child {
      font-weight: 700;
    }
.cm_fitment-table__vertical .fitment-row:empty {
      display: none;
    }
.cm_fitment-table__vertical .cm_vehicleSelector .cm_select_inner-select {
        margin-bottom: 4px;
      }
.cm_garage {
  position: relative;
}
.cm_garage .garage-button {
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
  }
.cm_garage .garage-icon-container {
    position: relative;
  }
.cm_garage .garage-icon {
    position: relative;
    width: 40px;
    height: 35px;
    margin: auto;
    transition: all 0.4s ease;
    filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(154deg) brightness(100%) contrast(102%);
  }
.cm_garage .garage-size {
    top: -4px;
    right: -6px;
    text-align: center;
    position: absolute;
  }
.cm_garage .cm_garageDropdown {
    position: absolute;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(218, 218, 218);
    padding: 15px;
    min-width: 250px;
    max-width: 300px;
    z-index: 100;
    right: 0;
    top: calc(100% + 10px);
  }
.cm_garage .cm_garageDropdown:before {
      border: 10px inset;
      content: '';
      display: block;
      height: 0;
      width: 0;
      border-color: transparent transparent rgb(218, 218, 218);
      border-bottom-style: solid;
      bottom: 100%;
      right: 11px;
      position: absolute;
    }
.cm_garage .cm_garageDropdown:after {
      border: 8px inset;
      content: '';
      display: block;
      height: 0;
      width: 0;
      border-color: transparent transparent rgb(255, 255, 255);
      border-bottom-style: solid;
      bottom: 100%;
      right: 13px;
      position: absolute;
    }
.cm_garage .vehicle-advantages {
    margin: 15px 0;
  }
.cm_garage .clear-garage {
    font-size: 0.85rem;
    cursor: pointer;
    align-self: flex-end;
  }
.cm_garage .clear-garage:hover {
      color: rgb(69, 99, 179);
    }
.cm_garage .cmRepeater_items {
    margin: 15px 0;
  }
.cm_garage .garage-item {
    color: rgb(0, 0, 0);
    display: flex;
    margin: 5px 0;
    cursor: pointer;
  }
.cm_garage .garage-item .select-model:hover {
      color: rgb(119, 119, 119);
      word-break: break-word;
    }
.cm_garage .garage-item .remove-model {
      margin: 0 0 0 10px;
    }
.cm_garage .garage-item .remove-model:hover .cm_icon {
        fill: rgb(238, 34, 34);
        stroke: rgb(238, 34, 34);
      }
.cm_garage .garage-item .cm_icon {
      fill: rgb(0, 0, 0);
      stroke: rgb(0, 0, 0);
      stroke-width: 3px;
      height: 0.7em;
    }
.cm_garage .select-model {
    flex: 1;
  }
.cm_garage .add-vehicle {
    align-self: center;
  }
.cm_garage .cmTemplate_empty .add-vehicle {
      margin-bottom: 10px;
    }
.cm_garage .cmTemplate_list .garage-content {
      display: flex;
      align-items: stretch;
      flex-direction: column;
    }
.cm_vehicle-info {
  margin: 20px 0;
}
.cm_vehicle-info_header {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
.cm_vehicle-info_header .cm_icon {
      padding-left: 10px;
      fill: rgb(0, 0, 0);
    }
.cm_vehicle-widget {
  margin-bottom: 10px;
}
.cm_vehicle-widget:empty {
    display: none;
  }
.cm_vehicle-widget__row .cm_vehicle-widget_select-container {
        flex-direction: row;
        flex: 1;
      }
.cm_vehicle-widget__row .cm_vehicle-widget_select {
        margin-right: 10px;
      }
.cm_vehicle-widget__row .cm_vehicle-widget_button-container {
        flex: 0 60px;
      }
.cm_vehicle-widget__column .cm_vehicle-widget_body,
      .cm_vehicle-widget__column .cm_vehicle-widget_select-container {
        flex-direction: column;
        align-items: stretch;
      }
.cm_vehicle-widget__column .cm_vehicle-widget_select {
        margin-bottom: 10px;
      }
.cm_vehicle-widget__column .cm_vehicle-widget_button {
        flex-grow: 1;
      }
.cm_vehicle-widget__column .cm_vehicle-widget_dropdowns .cm_vehicle-widget_title, .cm_vehicle-widget__column .cm_vehicle-widget_specifier .cm_vehicle-widget_title {
            padding: 0 0 5px;
          }
.cm_vehicle-widget__column .cm_vehicle-widget_dropdowns .cm_vehicle-widget_button-container .cm_vehicle-widget_button, .cm_vehicle-widget__column .cm_vehicle-widget_specifier .cm_vehicle-widget_button-container .cm_vehicle-widget_button {
            flex: 1 1 0;
            padding-left: 0;
            padding-right: 0;
          }
.cm_vehicle-widget_dropdowns,
  .cm_vehicle-widget_specifier {
    display: flex;
    flex-direction: column;
  }
.cm_vehicle-widget_dropdowns .cm_vehicle-widget_title, .cm_vehicle-widget_specifier .cm_vehicle-widget_title {
        font-size: 24px;
        line-height: 1em;
      }
.cm_vehicle-widget_dropdowns .cm_vehicle-widget_body, .cm_vehicle-widget_specifier .cm_vehicle-widget_body {
        display: flex;
      }
.cm_vehicle-widget_label {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
.cm_vehicle-widget_label .cm_vehicle-widget_title {
        flex: auto;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
      }
.cm_vehicle-widget_label .cm_vehicle-widget_subtitle {
        font-size: inherit;
        font-weight: 300;
      }
.cm_vehicle-widget_label .cm_vehicle-widget_body {
        flex: none;
      }
.cm_vehicle-widget_select-container {
    display: flex;
  }
.cm_vehicle-widget_select {
    line-height: 36px;
    position: relative;
    font-size: 18px;
    flex: 1;
    outline: none;
  }
.cm_vehicle-widget_button-container {
    display: flex;
  }
.cm_vehicle-widget_button-container .cm_vehicle-widget_button {
      margin: 0;
    }
.cm_vehicle-widget_button {
    flex: auto;
    text-align: center;
    cursor: pointer;
  }
.cm_vehicle-widget_button:not(.cm_vehicle-widget_button:last-of-type) {
      margin-right: 10px;
    }
.cm_vehicle-widget_button[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }
.cm_vehicle-widget_button__clear,
    .cm_vehicle-widget_button__go {
      text-transform: uppercase;
    }
.cm_vehicle-widget_header {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
.cm_vehicle-widget_header .cm_vehicle-widget_header-label {
      flex: 1;
    }
.cm_vehicle-widget_header .cm_icon {
      margin-left: 5px;
      width: 18px;
    }
.cm_vehicle-widget__search .cm_facetToggle {
    display: flex;
    align-items: center;
  }
.cm_vehicle-widget__search .cm_facetToggle .cm_facet-toggle_label {
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      margin-right: 10px;
    }
.cm_vehicle-widget__search .cm_vehicle-widget_title {
    padding-top: 0;
  }
.cm_vehicle-widget__verify-fitment.cm_vehicle-widget__collapsible .cm_vehicle-widget_title,
  .cm_vehicle-widget__search.cm_vehicle-widget__collapsible .cm_vehicle-widget_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.cm_vehicle-widget__verify-fitment.cm_vehicle-widget__collapsible .cm_vehicle-widget_title .cm_icon, .cm_vehicle-widget__search.cm_vehicle-widget__collapsible .cm_vehicle-widget_title .cm_icon {
      fill: rgb(0, 0, 0);
    }
.cm_vehicle-widget__search.cm_vehicle-widget__row .cm_vehicle-widget__collapsible .cm_icon-angle {
    display: none;
  }
.cm_vehicle-widget__search.cm_vehicle-widget__column .cm_vehicle-widget__collapsible .cmTemplate_locked .cm_vehicle-widget_button-container {
    display: none;
  }
.cm_vehicle-widget__dialog {
    margin-top: 10px;
  }
.cm_vehicle-widget__dialog.cm_vehicle-widget__column {
      height: 100%;
    }
.cm_vehicle-widget__dialog.cm_vehicle-widget__column .cm_vehicle-widget_dropdowns {
          height: 100%;
        }
.cm_vehicle-widget__dialog.cm_vehicle-widget__column .cm_vehicle-widget_body {
          flex: 1;
          position: relative;
        }
@media only screen and (max-width: 1024px) {
        .cm_vehicle-widget__dialog.cm_vehicle-widget__column .cm_dropdown {
          position: absolute;
          max-height: 30vh;
        }
  }
/* for mobile landscape mode */
@media (max-height: 450px) {
      .cm_vehicle-widget__dialog.cm_vehicle-widget__row .cm_dropdown {
        max-height: 25vh;
      }
      .cm_vehicle-widget__dialog.cm_vehicle-widget__column .cm_dropdown {
        max-height: 20vh;
      }
    }
.cm_vehicle-widget__home {
    margin: 0 auto;
  }
.cm_vehicle-widget__home.cm_vehicle-widget__row .cm_vehicle-widget_dropdowns {
        padding: 15px 0;
        flex-direction: row;
        align-items: center;
      }
.cm_vehicle-widget__home.cm_vehicle-widget__row .cm_vehicle-widget_title {
        flex: 0 270px;
        margin-right: 25px;
        font-size: 19px;
      }
.cm_vehicle-widget__home.cm_vehicle-widget__row .cm_vehicle-widget_title span {
          font-size: 30px;
          line-height: 22px;
          font-weight: bold;
        }
.cm_vehicle-widget__home .cm_vehicle-widget_title {
        text-transform: uppercase;
        text-align: center;
      }
.cm_vehicle-widget__home .cm_vehicle-widget_body {
        flex: 1;
      }
.cm_vehicle-widget__wheel-tire .cm_vehicle-widget_select-container {
    flex: 1;
  }
.cm_vehicle-widget__wheel-tire .cm_vehicle-widget_select-container .cm_select {
      flex: 1;
      margin-right: 10px;
    }
.cm_vehicle-widget__wheel-tire .cm_vehicle-widget_select-container .cm_select_toggle {
        height: 100%;
      }
.cm_vehicle-widget__wheel-tire .cm_vehicle-widget_select-container .cm_select__pretty {
        line-height: 45px;
      }
@media (max-width: 1024px) {
    .cm_vehicle-widget__wheel-tire .cm_vehicle-widget_select-container .cm_select {
      margin-right: 0;
      margin-bottom: 10px;
    }

    .cm_vehicle-widget__wheel-tire .cm_vehicle-widget_select-container,
    .cm_vehicle-widget__wheel-tire .cm_vehicle-widget_body {
      flex-direction: column;
    }

    .cm_vehicle-widget__wheel-tire .cm_vehicle-widget_button {
      width: 50%;
    }

      .cm_vehicle-widget__wheel-tire .cm_vehicle-widget_button-container {
        flex: unset;
      }
  }
.cm_verify-fitment {
  flex-wrap: nowrap;
}
.cm_verify-fitment .cm_vehicle-widget_title,
  .cm_verify-fitment_title {
    font-weight: 700;
  }
.cm_verify-fitment .cm_icon {
    margin: 20px;
    height: 20px;
  }
.cm_verify-fitment .cm_icon-times {
      stroke-width: 4px;
    }
.cm_verify-fitment_icon {
    display: flex;
    align-items: center;
  }
.cm_verify-fitment_fitment {
    flex: auto;
    font-size: 18px;
    padding-left: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
.cm_verify-fitment_fitment__container {
      display: flex;
      width: 100%;
    }
.cm_verify-fitment_vehicle {
    margin-right: 6px;
  }
.cm_verify-fitment_vehicle-notes {
      font-size: 16px;
      padding-left: 15px;
      margin: 0;
    }
.cm_verify-fitment_vehicle-info {
      display: flex;
      margin-top: 5px;
      font-size: 14px;
      align-items: center;
    }
.cm_verify-fitment_vehicle-info__key {
        margin-right: 10px;
      }
.cm_verify-fitment_vehicle-info__values {
        padding-left: 0;
        margin: 0 10px;
      }
.cm_verify-fitment_vehicle-info__values li {
          list-style: disc;
        }
.cm_verify-fitment_vehicle-info__values li::marker {
            font-size: 14px;
          }
@media (max-width: 570px) {
.cm_verify-fitment_vehicle-info {
        align-items: flex-start;
        flex-direction: column
    }
      }
.cm_verify-fitment_change {
    font-size: 14px;
    cursor: pointer;
  }
.cm_verify-fitment_change:before {
      content: '(';
    }
.cm_verify-fitment_change:after {
      content: ')';
    }
.cm_verify-fitment_notes {
    font-size: 16px;
  }
.cm_verify-fitment_compatible-parts {
    color: rgb(69, 99, 179);
  }
.cm_verify-fitment__yes {
    flex-direction: column;
  }
.cm_verify-fitment__yes .cm_verify-fitment_icon {
        background-color: inherit;
        align-items: flex-start;
      }
.cm_verify-fitment__yes .cm_verify-fitment_title {
        color: inherit;
      }
.cm_verify-fitment__yes .cm_icon {
      fill: green;
      stroke: green;
    }
.cm_verify-fitment__no .cm_verify-fitment_icon {
        background-color: inherit;
      }
.cm_verify-fitment__no .cm_verify-fitment_title {
        color: inherit;
      }
.cm_verify-fitment__no .cm_icon {
      fill: red;
      stroke: red;
    }
.cm_verify-fitment__aggressive .cm_verify-fitment_icon {
      background-color: inherit;
    }
.cm_verify-fitment__aggressive .cm_icon {
      fill: red;
      stroke: red;
    }
.cm_verify-fitment__unknown .cm_verify-fitment_icon {
      justify-content: center;
    }
.cm_verify-fitment__unknown .cm_icon {
      margin: 10px;
      height: 30px;
      width: 40px;
    }
.cm_dialog.VehicleWidgetDialog {
  align-items: center;
  justify-content: center;
}
.cm_dialog.VehicleWidgetDialog .dialog-container {
    flex-basis: 1000px;
  }
@media only screen and (max-width: 767px) {
.cm_dialog.VehicleWidgetDialog .dialog-container {
      flex-basis: 280px
  }
    }
@media only screen and (min-width: 768px) {
.cm_dialog.VehicleWidgetDialog .dialog-container {
      margin: 40px
  }
    }
.cm_dialog.VehicleWidgetDialog .close {
    top: -35px;
    right: 0;
  }
@media only screen and (min-width: 360px) {
.cm_dialog.VehicleWidgetDialog .close {
      right: -35px
  }
    }
.cm_dialog.VehicleWidgetDialog .dialog-content {
    overflow: visible;
  }
.cm_upsize-downsize {
  margin-bottom: 20px;
}
.cm_upsize-downsize-facettitle {
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
.cm_upsize-downsize.cm_empty {
    display: none;
  }
.cm_upsize-downsize .cm_button {
    width: 120px;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    line-height: normal;
  }
.cm_upsize-downsize .cm_button-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
.cm_wheel-tire-vehicle-info_header {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 20px;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
.cm_wheel-tire-vehicle-info_header .cm_icon {
      fill: rgb(0, 0, 0);
      margin-left: 10px;
    }
.cm_wheel-tire-vehicle-info_inner-container {
    padding: 10px;
    background-color: rgb(225, 224, 224);
  }
.cm_wheel-tire-vehicle-info .cm_wheel-tire-vehicle-info_inner-container .cm_vehicle-visualization-container {
    justify-content: center;
  }
.cm_wheel-tire-vehicle-info_title-vehicle {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }
.cm_wheel-tire-vehicle-info_recommended-fitment {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin: 10px 0;
  }
.cm_wheel-tire-vehicle-info_recommended-fitment__label {
      text-align: center;
      padding: 0 10px;
      white-space: nowrap;
    }
.cm_wheel-tire-vehicle-info_recommended-fitment::before,
    .cm_wheel-tire-vehicle-info_recommended-fitment::after {
      content: '';
      display: block;
      height: 2px;
      background: rgb(211, 211, 211);
      width: 100%;
    }
.cm_wheel-tire-vehicle-info_axle_container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(211, 211, 211);
  }
.cm_wheel-tire-vehicle-info_front-axle,
  .cm_wheel-tire-vehicle-info_rear-axle {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
.cm_wheel-tire-vehicle-info_attributes_container {
    padding-top: 10px;
  }
.cm_wheel-tire-vehicle-info_size-item__label {
    margin-right: 5px;
  }
.cm_wheel-tire-vehicle-info_size-item__value,
  .cm_wheel-tire-vehicle-info_axle-title,
  .cm_wheel-tire-vehicle-info_tire-sizes__item {
    font-weight: 700;
  }
.cm_wheel-tire-vehicle-info_tire-sizes {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    margin: 10px 0 0 0;
    gap: 2px;
  }
@media only screen and (max-width: 1024px) {
.cm_wheel-tire-vehicle-info {
    margin: 20px 0
}
  }
#easysearch-container,
.home-category-filter-sections {
  display: none;
}

.site-header.is-sticky {
    flex-direction: column;
  }

.site-header.halo-header-03 .header-middle-text > .item + .item {
    margin-left: 20px;
  }

.site-header .header-middle .header-middle-text .item:last-child {
    display: flex;
  }

.page-collections {
  padding-top: 0;
}

body.cm_parts .main-content {
    background-color: rgb(247, 247, 247);
  }

body.cm_search .breadcrumb-wrapper {
    padding-bottom: 0;
    margin-bottom: 20px;
  }

body:not(.cm_search) .breadcrumb-wrapper {
    padding-bottom: 20px;
  }

body:not(.cm_search):not(.cm_parts) .breadcrumb-wrapper {
    margin-bottom: 30px;
  }

.cm_search-box-root .cm_searchInput {
    padding-right: 2rem;
  }

.cm_search-box-root .cm_dropdown .item.nav-item,
    .cm_search-box-root .cm_dropdown .item.nav-item:last-child,
    .cm_search-box-root .cm_dropdown .section .section-title-div .section-title {
      margin-left: 0;
      padding-left: 6px;
    }

.cm_search-box-root .cm_dropdown .product-item .price {
        font-weight: 700;
      }

@media only screen and (min-width: 1024.02px) {

.cm_search-box-root .cm_dropdown {
      top: 100%
  }
  }

.cm_search-box-root__dialog .cm_searchInput {
      border: 1px solid rgb(204, 207, 214);
      padding-right: 4rem;
    }

.cm_search-box-root__dialog .cm_search-box_submit {
      background-color: rgb(93, 102, 115);
      border-color: rgb(93, 102, 115);
    }

.cm_SearchHeader .cm_dialogButton {
    line-height: 1.2;
  }

.cm_SearchHeader .cm_filter-icon {
    fill: rgb(255, 255, 255);
    stroke: rgb(255, 255, 255);
  }

.cm_SearchResult .product-card a.product-quickview {
      cursor: pointer;
    }

.cm_SearchResult .product-card a.product-quickview:hover {
        color: rgb(69, 99, 179);
      }

.cm_SearchResult .product-card .product-link {
      display: flex;
      justify-content: center;
      align-items: center;
      -o-object-fit: contain;
         object-fit: contain;
      height: 280px;
    }

@media (max-width: 426px) {

.cm_SearchResult .product-card .product-link {
        height: 170px
    }
      }

.cm_SearchResult .product-card img {
      position: unset;
      width: unset;
      max-width: 100%;
    }

.cm_SearchResult .product-card .image-two {
      display: none;
    }

.cm_SearchResult .product-card:hover .image-one {
        display: none;
      }

.cm_SearchResult .product-card:hover .image-two {
        display: block;
      }

.cm_SearchResult .product-card .product-title {
      height: 45px;
    }

.cm_FacetPanel .cm_btn {
    font-size: 14px;
  }

.cm_FacetPanel .facetholder {
    border-radius: 3px;
    border: 1px solid rgb(226, 228, 232);
    margin-bottom: 30px;
  }

.cm_FacetPanel .facettitle {
    margin: 0;
    font-weight: 700;
    line-height: 20px;
    padding: 12px 20px 12px 20px;
    font-family: Rajdhani, sans-serif;
    font-style: normal;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
  }

.cm_FacetPanel .facettitle .cm_icon {
      top: 13px;
      right: 15px;
    }

.cm_FacetPanel .facetbody {
    border-top: 1px solid rgb(226, 228, 232);
    margin: 0;
  }

.cm_FacetPanel .facetbody.collapsed {
      display: none;
    }

.cm_FacetPanel input {
    min-height: unset;
  }

.cm_FacetPanel .input input,
  .cm_FacetPanel .filter-input .input {
    line-height: 30px;
  }

.cm_FacetPanel .cm_facets .facetbody > div {
    padding: 12px 20px;
  }

.cm_FacetPanel .cm_filterChips .facetbody {
      padding: 12px 20px;
    }

.cm_FacetPanel .cm_filter-chips_button {
    display: block;
    margin: 5px auto 0 auto;
    padding: 0 15px;
    width: -moz-max-content;
    width: max-content;
    font-size: 13px;
    line-height: 2.2;
    border-color: rgb(128, 130, 135);
  }

.cm_FacetPanel .cm_Inputs input {
      padding: 0 10px;
    }

.cm_FacetPanel .cm_Inputs button {
      border-color: rgb(128, 130, 135);
    }

.cm_vehicle-widget .cm_select_inner-select {
    border: 1px solid rgb(204, 207, 214);
    height: 40px;
  }

.cm_vehicle-widget .cm_icon-angle {
    fill: rgb(255, 255, 255);
  }

.cm_vehicle-widget .cm_collapsible-elem .cm_vehicle-widget_dropdowns {
    padding-bottom: 10px;
  }

.cm_vehicle-widget_button {
    line-height: 13px;
    height: 40px;
  }

.cm_vehicle-widget_button__clear {
      background-color: white;
    }

.cm_vehicle-widget_header {
    color: rgb(255, 255, 255);
    font-family: Rajdhani, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }

.cm_vehicle-widget__header_container {
    background-color: rgb(46, 48, 51);
    width: 100%;
  }

.cm_vehicle-widget__header .cm_vehicle-widget_title {
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-align: left;
  }

.cm_vehicle-widget__header.cm_vehicle-widget__row .cm_vehicle-widget_title {
    margin-right: 15px;
    flex: 0 175px;
  }

.cm_vehicle-widget__home.cm_vehicle-widget__row .cm_vehicle-widget_title {
    flex: none;
    padding: 0;
    font-family: Rajdhani, sans-serif;
    font-weight: 700;
  }

.cm_vehicle-widget__home.cm_vehicle-widget__row .cm_vehicle-widget_title span {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }

.cm_vehicle-widget__home.cm_vehicle-widget__column .cm_vehicle-widget_dropdowns {
    padding-bottom: 10px;
  }

.cm_vehicle-widget__verify-fitment {
    margin-top: 12px;
  }

.cm_garage .garage-icon {
    height: 35px;
    width: 35px;
  }

.cm_garage .garage-size {
    top: -6px;
    right: -8px;
  }

.cm_garage .garage-content {
    color: rgb(0, 0, 0);
  }

.cm_garage .garage-item {
    font-size: 1rem;
  }

.cm_garage .select-model {
    text-align: left;
  }

.cm_garage .cmTemplate_empty {
    text-align: center;
  }

.cm_garage .cmTemplate_empty ul {
      text-align: left;
      padding-left: 10px;
    }

.cm_garage .cmTemplate_empty ul li {
        list-style: circle;
      }

.cm_garage-container {
    margin: 0 20px;
  }

.cm_garage-container.cm_layout-1 .garage-label {
      display: none;
    }

.cm_garage-container.cm_layout-2 {
      margin-right: 0;
    }

.cm_single-vehicle-garage {
  display: flex !important;
  align-items: center;
}

.cm_single-vehicle-garage .cm_changeButton {
    display: flex;
    align-items: center;
  }

.cm_single-vehicle-garage .garage-icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
    margin-right: 5px;
  }

.cm_single-vehicle-garage .go-to-results,
  .cm_single-vehicle-garage .clear-garage {
    display: block !important;
    flex: 1;
    margin-left: 10px;
    font-size: 0.85em;
  }

.cm_single-vehicle-garage .go-to-results:before, .cm_single-vehicle-garage .clear-garage:before {
      content: '(';
    }

.cm_single-vehicle-garage .go-to-results:after, .cm_single-vehicle-garage .clear-garage:after {
      content: ')';
    }

.cm_vehicle-categories .cm_vehicle-widget {
    margin-bottom: 10px;
  }

.cm_vehicle-categories .chevron {
    width: 24px;
    height: 24px;
    margin-left: auto;
  }

.cm_vehicle-categories_category-wrapper-repeater {
    margin: 0;
    padding-bottom: 15px;
  }

.cm_vehicle-categories_category-wrapper:hover .cm_vehicle-categories_category-title-container {
          -webkit-text-decoration: none;
          text-decoration: none;
        }

@media only screen and (min-width: 1024.02px) {
          .cm_vehicle-categories_category-wrapper:hover .cm_vehicle-categories_category-image-container {
            opacity: 0.6;
            overflow: visible;
          }
          .cm_vehicle-categories_category-wrapper:hover .cm_vehicle-categories_category-image {
            transform: scale3d(1.1, 1.1, 1);
            transition: all 0.5s ease;
          }
  }

.cm_vehicle-categories_link {
    background: white;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
  }

.cm_vehicle-categories_category-image-container {
    height: 200px;
    padding: 10px;
  }

.cm_vehicle-categories_category-image {
    max-height: 180px;
    transition: all 0.5s ease;
  }

.cm_vehicle-categories_category-title-container {
    background-color: rgb(255, 255, 255);
    display: flex;
    height: 3.2em;
    padding: 0 15px;
    align-items: center;
  }

.cm_vehicle-categories_category-title {
    height: unset;
    text-align: left;
    font-weight: bold;
    font-size: 1.3em;
    line-height: 20px;
    margin-top: 0;
    padding: 0;
    width: 90%;
  }

.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper-repeater {
    grid-template-columns: repeat(4, 1fr);
  }

@media only screen and (max-width: 1024px) {

.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper-repeater {
      grid-template-columns: repeat(3, 1fr)
  }
    }

@media only screen and (max-width: 768px) {

.cm_vehicle-categories__flat .cm_vehicle-categories_category-wrapper-repeater {
      grid-template-columns: repeat(2, 1fr)
  }
    }

@media (max-width: 425px) {
    .cm_vehicle-categories_category-image-container {
      height: 120px;
      padding: 10px;
    }
    .cm_vehicle-categories_category-image {
      max-height: 110px;
    }

    .cm_vehicle-categories_category-title-container {
      padding: 0 10px;
    }

    .cm_vehicle-categories_category-title {
      text-align: center;
      width: 100%;
    }

    .cm_vehicle-categories .chevron {
      display: none;
    }
  }

@media (max-width: 320px) {
    .cm_vehicle-categories_category-image-container {
      height: 130px;
    }
    .cm_vehicle-categories_category-image {
      max-height: 110px;
      max-width: 110px;
    }
  }

.cm_dialog.FacetDialog .buttons-container .cm_button {
      padding: 5px 10px;
    }

.cm_dialog.FacetDialog .cm_filterChips {
      padding-top: 0;
    }

.cm_dialog.FacetDialog .cm_filterChips .facetbody {
        padding: 0 10px;
      }

.cm_dialog.FacetDialog .cm_filterChips .facetdiv:last-child {
        margin-bottom: 0;
      }

.cm_dialog.FacetDialog .facetholder {
      margin: 0;
    }

.cm_dialog.FacetDialog .facettitle {
      padding-top: 12px;
      padding-bottom: 12px;
    }

.cm_dialog.FacetDialog .facettitle .cm_icon {
        top: 12px;
        right: 4px;
      }

.cm_dialog.FacetDialog .facetbody {
      margin: 0;
    }

.cm_dialog.FacetDialog .facetbody > div {
        padding: 10px 0;
      }
